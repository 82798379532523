/**
 * @author Ravi
 * This is a sample component. Don't edit this and reuse in your module.
 * Create a folder inside pages, like dashboard(If your working on dashboard implementation) and then add your code inside it.
 */
const Component = () => {
    return (
        <h3>
            Welcome to Pulse!            
        </h3>
    )
}
export default Component