export const redirectToLogin = () => {
    let BASE_URL: any;
    if (process.env.REACT_APP_MODE === 'test')
        BASE_URL = process.env.REACT_APP_DOMAIN_URL_PROD;
    else BASE_URL = window.location.origin;
    var anc = document.createElement('a');

    //console.log(window.location.hostname,"Logging Out")
    if(window.location.hostname.includes("thermaxglobal")){
        anc.setAttribute('href', `https://edge.thermaxglobal.com/logout`);

    }else  if (BASE_URL==='https://edge.thermaxglobal.com' || BASE_URL==='https://pulse.thermaxglobal.com'){
        anc.setAttribute('href', `https://edge.thermaxglobal.com/logout`);
    }
    else{
        anc.setAttribute('href', `${BASE_URL}/pulse-login`);
    }
    anc.click();
};