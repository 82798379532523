import Component from "../pages/index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import ForgotPasswordScreen from "../pages/ForgotPassword";
import ResetPasswordScreen from "../pages/ResetPassword";

/**
 * @author Ravi
 * Manages all the routes for the applucation. Add your routes inside the Route tag enclosed by Routes tag.
 * Define all the elements in Pages folder.
 */
function RouteManager() {
  return (
    <Router>
      <Routes>
        <Route path="/pulse-login" element={<Login />} />
        <Route path="/sample-route" element={<Component />} />
        <Route path="/pulse-login/forgot-password" element={<ForgotPasswordScreen />} />
        <Route path="/pulse-login/reset-password" element={<ResetPasswordScreen />} />
      </Routes>
    </Router>
  );
}
export default RouteManager;
