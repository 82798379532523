import { redirectToLogin } from "./redirectToLogin";

export function setLSPREFIX(disableRedirect:Boolean=false) {
  // If LS_PREFIX in local storage, return that.
  let LS_PREFIX:any = localStorage.getItem("lsprefix");


  if (LS_PREFIX) return JSON.parse(LS_PREFIX);
  else {
    // Look for cars.lsprefix
    let TMX_PREFIX:any = localStorage.getItem("cars.lsprefix");

   
  
    if (TMX_PREFIX) return JSON.parse(TMX_PREFIX);
  }

  // else redirects to login
  //console.log("LS_PREFIX not found");
  if(disableRedirect){
    return;
  }
  else
  redirectToLogin();
}
