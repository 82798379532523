import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/font-awesome-4.7.0/css/font-awesome.min.css'
import RouteManager from './routes';
import { setLSPREFIX } from './layout/footer/utilities/Functions/lsPrefix';
import axios from "axios";
import { useEffect } from 'react';

/**
 * @author Ravi
 * App entry point. 
 */
function App() {
    const LS_PREFIX = setLSPREFIX(true);
    const token = localStorage.getItem(LS_PREFIX + '.access_token');
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization']; // Optionally remove the header if token is null
    }

    return (
        <RouteManager />
    )
}
export default App;
