import { useState, useEffect } from "react";

import Logo from "../logo.png";
import bhelLogo from  "../assets/images/bhelupdated.jpg";
import { useNavigate } from "react-router-dom";

import { api_request } from "../layout/footer/utilities/Functions/APILayer";

const ForgotPasswordScreen = () => {
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [error, setError] = useState("");
  const [sucess, setSucess] = useState("");
   let BASE_URL:any;
 if(process.env.REACT_APP_MODE==="test"){
   BASE_URL = process.env.REACT_APP_DOMAIN_URL_PROD;
  }else{
   BASE_URL = window.location.origin;
  }
  const [LS_PREFIX, setLSPrefix] = useState("");
  useEffect(() => {
        api_request(`${BASE_URL}/ls-prefix`, "get")
            .then((response: any) => {
                setLSPrefix(response.data[0]["LS_PREFIX"])
            })
    }, [])

  const ResetPassword = () => {
    setError("");
    if (email)
      api_request(BASE_URL + `/exactapi/Users/reset`, "post", { email: email })
        .then((res: any) => {
          if (res.status === 204) {
            setSucess(
              "Reset password link sent to your registered email address."
            );
            setError("");
          }
        })
        .catch((error) => {
          setError("Authentication Failed. Please enter the valid email");
          // alert("login failed");
        });
    else {
      setError("Please enter the email.");
    }
  };

  return (
    <div className="row scroll height-100">
      <div className="col-md-9 col-sm-12 bg-white-light text-black p-0">
        {/* <img src={Logo} width="290" alt="logo" /> */}
        {
                    LS_PREFIX.length > 0 ? 
                    // (
                        // LS_PREFIX !== 'bhel' ? <img src={bhelLogo} width="290" height="200" alt="logo" /> :
                        //     <img src={Logo} width="290" alt="logo" />) : <></>
                        (
                            LS_PREFIX === 'bhel' ? <div style={{ height: "100%",
                                width: "100%",
                                backgroundImage: `url(${bhelLogo})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',}} className="bg-image"></div>: <img src={Logo} width="290" alt="logo" />
                            
                        )
                        :
                            ""

                }
      </div>
      <div className="col-md-3 col-sm-12  bg-light text-black">
        <div className="p-2 loginModule">
          {/* <form> */}
          <h4 className="mb-2">Reset Password</h4>
          <label>Email</label>
          <div className="relative">
            <div className="input-group mt-1 mb-4">
              <span className="input-group-text">
                <i className="fa fa-user"></i>
              </span>
              <input
                id="email"
                type="email"
                className="form-control"
                placeholder="Email"
                onChange={(e: any) => {
                  console.log(e.target.value);
                  setError(error);
                  setemail(e.target.value);
                }}
                // onBlur={formik.handleBlur}
                value={email}
              />
            </div>

            <div className="error">
              {error && <span className="size-a text-danger">{error}</span>}
              {sucess && <span className="size-a text-black">{sucess}</span>}
            </div>
          </div>

          <button
            type="reset"
            className="btn btn-primary px-5 btn-sm btnReset "
            onClick={ResetPassword}
          >
            Reset
          </button>
          <div className="col-md-6 mt-2">
            <a
              role="button"
              className="size-b text-black pointer "
              onClick={() => navigate("/pulse-login")}
            >
              Go to log-in
            </a>
          </div>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
