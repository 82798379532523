import axios from "axios";
import { API_HEADERS } from "../../../../config/global_config";
import { setLSPREFIX } from "./lsPrefix";

/**
 * @author Ravi Sankar
 * @param endpoint
 * @param method
 * @param headers
 * @param payload
 * API request handler
 */
let access_token:any='';
try{
    let LS_PREFIX = setLSPREFIX(true);
    access_token =  localStorage.getItem(`${LS_PREFIX}.access_token`)||''
}catch (err){
    console.error(err,"access_token_not_found");
}
export async function api_request(endpoint: string, method: string, payload = {}, headers = {}) {
    // Set the Authorization header
    const defaultHeaders = {
        'Authorization': `Bearer ${access_token}`,
        ...headers,
    };

    // Handle GET Requests
    if (method.toLowerCase() === "get") {
        const response = await axios.get(endpoint, { headers: defaultHeaders });
        return response;
    }
    // Handle POST Requests
    else if (method.toLowerCase() === "post") {
        const response = await axios.post(endpoint, payload, { headers: defaultHeaders });
        return response;
    }
    // Handle DELETE Requests
    else if (method.toLowerCase() === "delete") {
        const response = await axios.delete(endpoint, { headers: defaultHeaders });
        return response;
    }
}
