import { useFormik } from "formik";
import { useEffect, useState } from "react";
import bhelLogo from  "../assets/images/bhelupdated.jpg";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Loader from "react-loader";
import { useNavigate } from "react-router-dom";
import Logo from "../logo.png";
import { api_request } from "../layout/footer/utilities/Functions/APILayer";

const Login = () => {
    useEffect(() => {
        api_request(`${BASE_URL}/ls-prefix`, "get")
            .then((response: any) => {
                setLSPrefix(response.data[0]["LS_PREFIX"])
            })
    }, [])

    const validateEmail = (email:any) => {
        // Validating if email is present and valid 
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!email) {
            return "Email is required";
        } else if (!emailRegex.test(email)) {
            return "Invalid email address";
        }
        return null;
    };
    
    const validatePassword = (password:any) => {
        // Presently only validating if password is empty or not. 
        if (!password) {
            return "Password is required";
        }
        return null;
    };
    const navigate = useNavigate();
    let BASE_URL: any;
    let selectedUnitId: any;
    let customerAllowed: any;
    if (process.env.REACT_APP_MODE === "test") {
        BASE_URL = process.env.REACT_APP_DOMAIN_URL_PROD;
    } else {
        BASE_URL = window.location.origin;
    }
    console.log(BASE_URL, 'baseurl');

    const [LS_PREFIX, setLSPrefix] = useState("");

    if (LS_PREFIX === 'bhel') {
        document.title = 'RMDS'
    }
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [selectedUnitStored, setSelectedUnitStored] = useState<Boolean>(false);
    const [custAllowedStored, setCustAllowedStored] = useState<Boolean>(false);
    const [profileStored, setProfileStored] = useState<Boolean>(false);
    const [accessObjStored, setaccessObjStored] = useState<Boolean>(false);

    const [recordedUserActivity, setRecordedUserActivity] = useState<Boolean>(false);
    type UserActivityProps = {
        activity: string,
        time: string,
        platform: string,
        email: string
    }
    const record_user_activity = (email: string, accesstoken: any) => {
        const user_activities_url = BASE_URL + `/exactapi/useractivities?access_token=${accesstoken}`
        const currentTime = new Date();
        const year = currentTime.getFullYear();
        const month = String(currentTime.getMonth() + 1).padStart(2, '0');
        const day = String(currentTime.getDate()).padStart(2, '0');
        const hours = String(currentTime.getHours()).padStart(2, '0');
        const minutes = String(currentTime.getMinutes()).padStart(2, '0');
        const seconds = String(currentTime.getSeconds()).padStart(2, '0');

        const formattedTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        let user_activities_payload: UserActivityProps = {
            "activity": "login",
            time: formattedTime,
            platform: "web",
            email: email
        }
        api_request(user_activities_url, "post", user_activities_payload).then(
            () => {
                setRecordedUserActivity(true);
            }
        ).catch(
            (err: any) => {
                // Avoiding user login getting blocked due to api failure (local storage is already set at this point).
                setRecordedUserActivity(true);
                console.log(err)
            }
        )

    }
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validate: (values) => {
            const errors:any = {};
            const emailError = validateEmail(values.email);
            if (emailError) {
                errors.email = emailError;
            }
            const passwordError = validatePassword(values.password);
            if (passwordError) {
                errors.password = passwordError;
            }
            return errors;
        },
        validateOnChange: true,  
        validateOnBlur: true,    
        onSubmit: function (values) {
            setShowLoading(true);
            localStorage.clear();
            let path;
            if (process.env.REACT_APP_MODE === 'test') {
                // if(window.location.origin!=='data.exactspace.co' && window.location.origin!=='sandbox.exactspace.co')
                path = 'exactapi/Users/login'
                console.log(BASE_URL, path, 'path');
            }
            else if (BASE_URL.includes("exactspace.co") && process.env.REACT_APP_MODE !== 'test') {
                path = 'login'
            }
            else {
                path = 'exactapi/Users/login'
            }
            api_request(`${BASE_URL}/${path}`, "post", values)
                .then((res: any) => {
                    if (res.status === 200) {
                        // localStorage.setItem("access_token", res.data.id);
                        const accesstoken = res.data.id
                        localStorage.setItem(LS_PREFIX + ".access_token", res.data.id);
                        setError("");

                        const get_profile_api = BASE_URL + `/sensordata/getredis?access_token=${accesstoken}`
                        const get_userprofile_api = `${BASE_URL}/exactapi/userprofiles?filter={"where":{"email":"${values.email}"}}&access_token=${accesstoken}`;
                        const get_unit_api = BASE_URL + "/exactapi/units/"
                        const get_customer_relation_api = BASE_URL + `/exactapi/customers/alter?access_token=${accesstoken}`

                        const getUserProfile = api_request(get_userprofile_api, "GET").then((response: any) => {
                            localStorage.setItem(LS_PREFIX + ".user", JSON.stringify(response.data[0]));
                            //   localStorage.setItem("profile", JSON.stringify(response.data[0]));
                            localStorage.setItem(LS_PREFIX + ".profile", JSON.stringify(response.data[0]));
                            localStorage.setItem(LS_PREFIX + ".userToken", JSON.stringify(res.data.id));

                            localStorage.setItem(LS_PREFIX + ".selectedCustomerid", JSON.stringify(response.data[0]["accessAllowed"][0]["customerId"]));
                            localStorage.setItem(LS_PREFIX + ".selectedSiteid", JSON.stringify(response.data[0]["accessAllowed"][0]["sitesId"]));
                            localStorage.setItem("lsprefix", JSON.stringify(LS_PREFIX));
                            let valueuser: any = response.data[0]
                            console.log(valueuser, 'acc6');

                            let accessObj: any = {};
                            api_request(`${BASE_URL}/exactapi/configurations?filter={"where":{"type":"bu", "name":{"inq":"${valueuser.bu[0]}"}}}&access_token=${accesstoken}`, "get").then((resBu: any) => {
                                console.log("resBu", resBu)
                                api_request(`${BASE_URL}/exactapi/configurations?filter={"where":{"type":"role", "name":"${valueuser.department}"}}&access_token=${accesstoken}`, "get").then((resRole: any) => {
                                    console.log("resRole", resRole)
                                    api_request(`${BASE_URL}/exactapi/configurations?filter={"where":{"type":"feature"}}&access_token=${accesstoken}`, "get").then((resFeatures: any) => {
                                        console.log("resFeatures", resFeatures)
                                        const bu = resBu.data;
                                        const roles = resRole.data;
                                        const features = resFeatures.data;

                                        bu.forEach((b: any) => {
                                            accessObj[b.name] = {};
                                            roles.forEach((role: any) => {
                                                accessObj[b.name][role.name] = {};
                                                features.forEach((feature: any) => {
                                                    accessObj[b.name][role.name][feature.name] = ""
                                                })
                                            })
                                        })

                                        api_request(`${BASE_URL}/exactapi/configurations?filter={"where":{"type":"policy"}}&access_token=${accesstoken}`, "get").then((resPolicy: any) => {
                                            console.log("resPolicy", resPolicy)
                                            const policies = resPolicy.data;
                                            features.forEach((f: any) => {
                                                bu.forEach((b: any) => {
                                                    roles.forEach((r: any) => {
                                                        policies.forEach((p: any) => {
                                                            let result = policies.find((item: any) => (item.featureId === f.id) && (item.roleId === r.id) && (item.buId == b.id));
                                                            if (result == undefined) {
                                                                accessObj[b.name][r.name][f.name] = ""
                                                            } else {
                                                                accessObj[b.name][r.name][f.name] = result.access
                                                            }
                                                        })
                                                        // let result = policies.find((item :any)=> (item.featureId === f.id) && (item.roleId === r.id));
                                                        // console.log(result)
                                                        // if(result["id"] === "662f3a62b2a6f300079bcd86"){
                                                        //     console.log("objeeee", result)
                                                        // }
                                                        // if(result == undefined){
                                                        //     accessObj[b.name][r.name][f.name] = ""
                                                        // }else{
                                                        //     accessObj[b.name][r.name][f.name]= result.access
                                                        // }
                                                    })
                                                })
                                            })

                                            localStorage.setItem(LS_PREFIX + '.accessObject', JSON.stringify(accessObj));
                                            setaccessObjStored(true);
                                            console.log(accessObj)
                                        })
                                    })
                                })

                            })

                            for (let accessAllowed of response.data[0]["accessAllowed"]) {
                                if (accessAllowed["units"].length > 0) {
                                    selectedUnitId = JSON.stringify(accessAllowed["units"][0])
                                    localStorage.setItem(LS_PREFIX + ".selectedUnitid", selectedUnitId);
                                    break;
                                }
                            }
                            const selectedUnitList = response.data[0][
                                'accessAllowed'
                            ].filter((item: any) => {
                                if (item['units'].length > 0) return true;
                                else return false;
                            });
                            if (selectedUnitList.length === 0) setError(
                                "Login failed"
                            );
                            api_request(get_unit_api + selectedUnitList[0]['units'][0] + `?access_token=${accesstoken}`, "GET").then((response: any) => {
                                localStorage.setItem(LS_PREFIX + ".selectedUnit", JSON.stringify(response.data));
                            }).then(
                                () => {
                                    setSelectedUnitStored(true)
                                }
                            )

                            let unitList: any = []
                            let customerList: any = []
                            let siteList: any = []
                            for (const accessAllowed of response.data[0]["accessAllowed"]) {
                                unitList = unitList.concat(accessAllowed["units"])
                                customerList.push(accessAllowed["customerId"])
                                siteList.push(accessAllowed["sitesId"])
                            }

                            const unitQuotes = unitList.map((unit: any) => `"${unit}"`);
                            const unitsToQuery = unitQuotes.join(',')

                            const sitesQuotes = siteList.map((site: any) => `"${site}"`);
                            const sitesToQuery = sitesQuotes.join(',')

                            const customerQuotes = customerList.map((customer: any) => `"${customer}"`);
                            const customerToQuery = customerQuotes.join(',')

                            localStorage.setItem(LS_PREFIX + ".unitsAllowed", JSON.stringify(unitList));

                            api_request(get_customer_relation_api, "POST", JSON.parse(`{"where":{"id":{"inq":[${customerToQuery}]}},"include":{"relation":"sites","scope":{"where":{"id":{"inq":[${sitesToQuery}]}},"include":{"relation":"units","scope":{"where":{"id":{"inq":[${unitsToQuery}]}}}}}}}`))
                                .then((response: any) => {
                                    let customersWithUnits: any = response.data["result"].filter(
                                        (item: any) => {
                                            let siteHasUnits: Boolean = true;
                                            item.sites.forEach(
                                                (site: any) => {
                                                    if (site.units.length === 0)
                                                        siteHasUnits = false
                                                }
                                            )
                                            return siteHasUnits;
                                        }
                                    )
                                    customerAllowed = {
                                        customer: customersWithUnits,
                                        lastSetTime: new Date(),
                                        selectedUnit: selectedUnitId,
                                    };
                                    localStorage.setItem(LS_PREFIX + ".customerAllowed", JSON.stringify(customerAllowed));
                                    record_user_activity(values.email, accesstoken)
                                }).then(
                                    () => {
                                        // window.open(BASE_URL + "/pulse-master/my-tasks", "_self");
                                        setCustAllowedStored(true)
                                        setShowLoading(false);
                                    }
                                );
                            setProfileStored(true);
                        })
                    }
                    else {
                        if (res.status === 429) {
                            setError("Login failed! Please try resetting your password.");
                        }
                    }
                })
                .catch((error) => {
                    setShowLoading(false);
                    console.log(error);
                    if (error.response && error.response.status === 429) {
                        setError("Login failed! Please try resetting your password.");
                    } else {
                        setError("Authentication Failed. Please enter the correct username and password.");
                    }
                });
        },
    });

    useEffect(
        () => {

            if (selectedUnitStored && custAllowedStored && profileStored && recordedUserActivity && accessObjStored) {
                console.log('login success');
                setShowLoading(false)
                window.open(BASE_URL + "/pulse-master/my-tasks", "_self");

            }
            console.log(accessObjStored, 'acc');

        }, [selectedUnitStored, custAllowedStored, profileStored, recordedUserActivity, accessObjStored]
    )
    function togglePassword() {
        setShowPassword(!showPassword);
    }
    useEffect(() => {
        if (
            formik.errors.password === undefined &&
            formik.errors.email === undefined
        ) {
            setError(error);
        } else {
            setError("");
        }
    }, [formik.errors]);

    return (
        <div className="row scroll height-100">
            <div className="col-md-9 col-sm-12 bg-white-light text-black p-0">
                {
                    LS_PREFIX.length > 0 ? 
                    // (
                        // LS_PREFIX !== 'bhel' ? <img src={bhelLogo} width="290" height="200" alt="logo" /> :
                        //     <img src={Logo} width="290" alt="logo" />) : <></>
                        (
                            LS_PREFIX === 'bhel' ? <div style={{ height: "100%",
                                width: "100%",
                                backgroundImage: `url(${bhelLogo})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',}} className="bg-image"></div>: <img src={Logo} width="290" alt="logo" />
                            
                        )
                        :
                            ""

                }
            </div>
            <div className="col-md-3 col-sm-12  bg-light text-black">
                <div className="p-2 loginModule">
                    <form onSubmit={formik.handleSubmit}>
                        <h4 className="mb-2">Login</h4>
                        <label>Email</label>
                        <div className="relative">
                            <div className="input-group mt-1 mb-4">
                                <span className="input-group-text">
                                    <i className="fa fa-user"></i>
                                </span>
                                <input
                                    id="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                            </div>

                            <div className="error">
                                {formik.touched.email && formik.errors.email && (
                                    <span className="size-a text-danger">
                                        {formik.errors.email}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <label> Password</label>
                            <div className="relative">
                                <div className="input-group mt-1 mb-5">
                                    <span className="input-group-text">
                                        <i className="fa fa-lock"></i>
                                    </span>
                                    <input
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        className="form-control"
                                        placeholder="Password"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                    />

                                    <div className="input-group-btn">
                                        <span className="input-group-text">
                                            {showPassword ? (
                                                <VisibilityOffIcon
                                                    onClick={togglePassword}
                                                ></VisibilityOffIcon>
                                            ) : (
                                                <RemoveRedEyeOutlinedIcon
                                                    onClick={togglePassword}
                                                ></RemoveRedEyeOutlinedIcon>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 text-end passwordWrap">
                                    <a
                                        role="button"
                                        className="size-a text-primary "
                                        onClick={() => navigate("/pulse-login/forgot-password")}
                                    >
                                        Forgot Password
                                    </a>
                                </div>
                                <div className="error">
                                    {formik.touched.password && formik.errors.password && (
                                        <span className="size-a text-danger">
                                            {formik.errors.password}
                                        </span>
                                    )}
                                    <span className="size-a text-danger">{error}</span>
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary px-5 btn-sm">
                            Login
                        </button>
                    </form>
                    <Loader
                        loaded={!showLoading}
                        lines={10}
                        length={10}
                        width={5}
                        radius={10}
                        corners={1}
                        rotate={0}
                        direction={1}
                        color="#000"
                        speed={1}
                        trail={60}
                        shadow={false}
                        hwaccel={false}
                        className="spinner"
                        // zIndex={2e9}
                        top="73%"
                        left="82%"
                        scale={0.5}
                        loadedClassName="loadedContent"
                    />
                </div>
            </div>
        </div>
    );
};

export default Login;